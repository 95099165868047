import React from 'react';
import classNames from 'classnames';
import { Book } from 'types';
import Link from 'next/link';
import Image from 'next/image';

import Links from '~/lib/helpers/link-helper';
import styles from '~/styles/components/books-mosaic.module.scss';

import { images } from 'sdk';

export default function BooksMosaic({ books, disableClick, bookImagePaths }: { books?: Book[]; disableClick?: boolean; bookImagePaths?: string[]; }) {
  if (!books && !bookImagePaths) return null;

  if (bookImagePaths?.length > 3) bookImagePaths = bookImagePaths.slice(0, 3);

  let bookImages = [];

  if (bookImagePaths) {

    bookImages = bookImagePaths.map((image, index) => {
      const gridClasses = classNames(styles['book-image'], {
        [styles['book-image--secondary']]: index === 1,
        [styles['book-image--tertiary']]: index === 2,
      });

      if (!image) return null;

      if (disableClick) {
        return (
          <div key={image} className={gridClasses}>
            <Image
              className={styles['book-image-thumbnail']}
              src={images(image).book.thumb}
              alt="book-image"
              unoptimized
              fill
            />
          </div>
        );
      }

      return (
        <div key={image} className={gridClasses}>
          <Link href={Links.book(books[index]?.id).show} passHref>
            <Image
              className={styles['book-image-thumbnail']}
              src={images(image).book.thumb}
              alt="book-image"
              unoptimized
              fill
            />
          </Link>
        </div>
      );

    });
  }

  if (books) {
    bookImages = books.map((book, index) => {
      const gridClasses = classNames(styles['book-image'], {
        [styles['book-image--secondary']]: index === 1,
        [styles['book-image--tertiary']]: index === 2,
      });

      if (!book) return null;

      if (disableClick) {
        return (
          <div key={book?.id} className={gridClasses}>
            <Image
              className={styles['book-image-thumbnail']}
              src={book?.photo_path ? images(book?.photo_path).book.thumb : images(book.photo_url).book.thumb}
              alt={book?.title}
              unoptimized
              fill
            />
          </div>
        );
      }

      return (
        <div key={book?.id} className={gridClasses}>
          <Link href={Links.book(book?.id).show} passHref>
            <Image
              className={styles['book-image-thumbnail']}
              src={book?.photo_path ? images(book?.photo_path).book.thumb : images(book.photo_url).book.thumb}
              alt={book?.title}
              unoptimized
              fill
            />
          </Link>
        </div>
      );
    });
  }

  const bookImagePathsLength = bookImagePaths?.length || 0;
  const booksLength = books?.length || 0;

  const length = bookImagePathsLength || booksLength;

  return (
    <div
      className={classNames(styles['image-container'], {
        [styles['two-books']]: length === 2,
        [styles['three-books']]: length === 3,
      })}
    >
      {bookImages}
    </div>
  );
}
