import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { Book, DefaultFacets, Title, TypesenseSearchProps } from 'types';
import {
  CONDITIONS,
  SORT_SELECT_OPTIONS,
  sortSelectValue,
  parseSortSelectValue,
  clearFacetValue,
  isFacetCategorySelected,
  isFacetFilterSelected,
  mergeFilters,
  states,
  numberWithCommas,
  removeUnsupportedFilters,
  parseIndex,
  mapQueryBy,
  mapSortBy,
} from 'sdk';
import { Links } from '../lib/helpers/link-helper';

import Button from './button';
import Pill from './pill';
import Select from './select';

import styles from '../styles/components/grid-facets.module.scss';
import PriceSlider from './price-slider';
import Input from './form/input';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import useAnalytics from '../lib/hooks/use-analytics';
import { Flex } from './layout/flex';
import { ActionType } from '~/state/types';
import { useGlobalState } from '~/state';
import pluralize from 'pluralize';
import Spacer from './spacer';

export function HashtagFacets({
  searchResults,
  searchOptions,
  loading,
  location,
}: {
  searchResults: SearchResponse<any>;
  searchOptions: TypesenseSearchProps;
  loading: boolean;
  location?: string;
}) {
  const router = useRouter();
  const [showAllHashTags, setShowAllHashTags] = useState(false);
  const { analyticEvents } = useAnalytics();

  const tagFacets = searchResults?.facet_counts?.find(
    (c) => c.field_name === 'hashtags'
  )?.counts;

  if (!tagFacets?.length) return null;

  function handleClearAllClick(option: DefaultFacets) {
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: searchOptions?.index || 'books',
          params: {
            ...searchOptions.params,
            filter_by: clearFacetValue(
              searchOptions?.params?.filter_by,
              option
            ),
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  const hasHashtagSelected = isFacetCategorySelected(
    searchOptions?.params?.filter_by,
    'hashtags'
  );
  const hashtagOptions = tagFacets
    .slice(0, showAllHashTags ? 100 : 10)
    .map((tag) => {
      const { count, value } = tag;
      return (
        <Pill
          loading={loading}
          key={value}
          text={`${value} (${numberWithCommas(count)}) `}
          style='secondary'
          small
          active={isFacetFilterSelected(
            searchOptions?.params?.filter_by,
            'hashtags',
            value
          )}
          onClick={() => {
            analyticEvents?.generalTrack('Search Action', {
              type: 'filter-action',
              subType: 'hashtags',
              subTypeValue: value,
              location: location || 'unknown'
            });
          }}
          link={{
            href: Links.searchWithQuery({
              router,
              options: {
                ...searchOptions,
                index: searchOptions?.index || 'books',
                params: {
                  ...searchOptions.params,
                  filter_by: mergeFilters(
                    searchOptions?.params?.filter_by,
                    `hashtags:${value}`
                  ),
                  page: 0,
                },
              },
            }),
          }}
        />
      );
    });

  return (
    <div className={styles.facet}>
      <div className={styles['facet-title-container']}>
        <h2 className={styles['facet-title']}>Tags</h2>
        <div>
          {hasHashtagSelected && (
            <span
              className={styles['facet-title-link']}
              onClick={() => handleClearAllClick('hashtags')}
            >
              Clear Selected
            </span>
          )}
          <span
            className={styles['facet-title-link']}
            onClick={() => setShowAllHashTags(!showAllHashTags)}
          >
            {showAllHashTags ? 'Collapse' : 'See All'}
          </span>
        </div>
      </div>
      <div className={styles['facet-tags-container']}>{hashtagOptions}</div>
    </div>
  );
}

interface GridFacetsProps {
  searchResults: UseQueryResult<SearchResponse<Book | Title>, unknown>;
  searchOptions: TypesenseSearchProps;
  location?: string;
}

export default function GridFacets(props: GridFacetsProps) {
  const router = useRouter();
  const { searchResults, searchOptions } = props;
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllStates, setShowAllStates] = useState(false);
  const [query, setQuery] = useState(
    props.searchOptions.params?.q && props.searchOptions.params?.q !== '*'
      ? props.searchOptions.params?.q
      : ''
  );
  const { analyticEvents } = useAnalytics();
  const {dispatch } = useGlobalState();

  const IS_TITLE_QUERY = searchOptions?.index === 'titles';

  function handleClearQuery() {
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: props.searchOptions?.index || 'books',
          params: {
            ...searchOptions.params,
            q: '',
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  const renderDealsFacet = () => {
    if (!searchResults?.data?.found || searchOptions.index === 'titles') return null;

    const options = (
      <div className={styles['facet-options']}>
        {['Seller discounts available', 'Free shipping eligible'].map((type, index) => {
          let filter: string;
    
          if (type === 'Seller discounts available') {
            filter = '[percent,value]';

            
          } else {
            filter = 'shipping';
          }

          let checked = false;

          if (filter === 'shipping') {
            checked = isFacetFilterSelected(searchOptions?.params?.filter_by, 'discounts.type', 'shipping');
          } else {
            //check if either percent or value is in the filter
            const filterValues = filter.replace(/[\[\]']+/g, '').split(',');
            checked = filterValues.some((filterValue) =>
              isFacetFilterSelected(searchOptions?.params?.filter_by, 'discounts.type', filterValue)
            );
          }
          return (
            <>
              <div className={styles['facet-checkbox']} key={type}>
                <input
                  onChange={() => {
                    analyticEvents?.generalTrack('Search Action', {
                      type: 'filter-action',
                      subType: 'discounts.type',
                      subTypeValue: type,
                      location: props.location || 'unknown',
                    });
                    handleOnClick('discounts.type', filter, 'AND');
                  }}
                  id={type}
                  type='checkbox'
                  name={type}
                  value={type}
                  checked={checked}
                />
                <label htmlFor={type}>
                  {type}
                </label>
              </div>
            </>
          );
        })}
      </div>
    );

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Find a deal</h2>
        </div>
        {options}
      </div>
    );
  };

  const allDefaultFacets: DefaultFacets[] = [
    'hashtags',
    'format.display',
    'genre.display',
    'condition',
    'amount',
    'ships_from_state.name',
    'type.display',
    'discounts.type',
  ];

  function handleClearAllFilters(option: DefaultFacets[]) {
    const index = 'books';
    let filteredBy = clearFacetValue(searchOptions?.params?.filter_by, option);

    if (searchOptions.index === 'titles') {
      filteredBy = removeUnsupportedFilters(filteredBy, index, searchOptions?.index);
    }

    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: index,
          params: {
            ...searchOptions.params,
            sort_by: mapSortBy(index),
            filter_by: filteredBy,
            query_by: mapQueryBy(index),
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  useEffect(() => {
    if (query?.length === 0) {
      handleClearQuery();
    }
  }, [query]);

  function handleOnClick(
    facetName: string,
    value: string,
    action: 'AND' | 'OR' = 'AND'
  ) {
    analyticEvents?.generalTrack('Search Action', {
      type: 'filter-action',
      subType: facetName,
      subTypeValue: value,
      location: props.location || 'unknown'
    });
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: props.searchOptions?.index || 'books',
          params: {
            ...searchOptions.params,
            filter_by: mergeFilters(
              searchOptions?.params?.filter_by,
              `${facetName}:=${value}`,
              action
            ),
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  function handleIndexChange(ev: any) {
    const { index, sort_by } = parseSortSelectValue(ev.target.value);
    analyticEvents?.generalTrack('Search Action', {
      type: 'filter-action',
      subType: 'sort-results',
      sortBy: sort_by,
      location: props.location || 'unknown'
    });
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index,
          params: {
            ...searchOptions.params,
            sort_by,
            filter_by: removeUnsupportedFilters(
              searchOptions.params?.filter_by || '',
              index,
              searchOptions?.index
            ),
            query_by: mapQueryBy(index),
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  function handleQueryChange() {
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: props.searchOptions?.index || 'books',
          params: {
            ...searchOptions.params,
            q: query,
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  function renderConditionFacets() {
    const conditionFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === 'condition'
    )?.counts;
    if (!conditionFacets?.length) return null;

    const conditionOptions = CONDITIONS.map((condition) => {
      const conditionCount = conditionFacets.find(
        (c) => c.value === condition
      )?.count;
      return (
        <div className={styles['facet-options']} key={condition}>
          <div className={styles['facet-checkbox']}>
            <input
              onChange={() => handleOnClick('condition', condition, 'OR')}
              id={condition}
              type='checkbox'
              name={condition}
              value={condition}
              checked={isFacetFilterSelected(
                searchOptions?.params?.filter_by,
                'condition',
                condition
              )}
            />
            <label htmlFor={condition}>
              {condition}
              {conditionCount && (
                <span>{numberWithCommas(conditionCount)}</span>
              )}
            </label>
          </div>
        </div>
      );
    });

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Condition</h2>
        </div>
        {conditionOptions}
      </div>
    );
  }

  function listingsVsTitlesFacet() {
    if (!searchResults?.data?.found) return null;
    const options = (
      <div className={styles['facet-options']}>
        {['Listings', 'Titles'].map((option) => {
          const index = option === 'Listings' ? 'books' : 'titles';
          return (
            <>
              <div className={styles['facet-checkbox']} key={option}>
                <input
                  onChange={() => {
                    analyticEvents?.generalTrack('Search Action', {
                      type: 'tab-sort-results',
                      sortBy: option,
                      location: props.location || 'unknown',
                    });
                    router.push(
                      Links.searchWithQuery({
                        router,
                        options: {
                          ...searchOptions,
                          index,
                          params: {
                            ...searchOptions.params,
                            sort_by: mapSortBy(option === 'Listings' ? 'books' : 'title_popularity_desc_high'),
                            filter_by: removeUnsupportedFilters(
                              searchOptions.params?.filter_by || '',
                              index,
                              searchOptions?.index
                            ),
                            query_by: mapQueryBy(index),
                            page: 0,
                          },
                        },
                      }),
                      null,
                      { shallow: true, scroll: true }
                    );
                  }}
                  id={option}
                  type='radio'
                  name={option}
                  value={option}
                  checked={searchOptions?.index === index}
                />
                <label htmlFor={option} className={styles['options-container']}>
                  {option}

                  <div>
                    {option === 'Listings' ? (
                      <div className={styles['options-subtext']}>Show all available copies on Pango</div>
                    ) : (
                      <div className={styles['options-subtext']}>Group results by book title</div>
                    )}
                  </div>
                </label>
              </div>
            </>
          );
        })}
      </div>
    );

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title-search-options']}>Search options</h2>
        </div>
        {options}
      </div>
    );
  }

  function renderPriceFacet() {
    if (!searchResults?.data?.found) return null;
    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Price Range</h2>
        </div>
        <PriceSlider
          searchResults={searchResults}
          searchOptions={searchOptions}
          location={props.location}
        />
      </div>
    );
  }

  function renderStateFacet() {
    const SHOULD_HIDE_STATE_FACETS = router?.pathname?.includes('/bookstore/');
    const facetKey: string = 'ships_from_state.name';
    const stateFacets = searchResults?.data?.facet_counts?.find(
      (c) => c.field_name === facetKey
    )?.counts;
    if (!stateFacets?.length || SHOULD_HIDE_STATE_FACETS) return null;

    const stateOptions = states
      .map((_state) => _state.name)
      .slice(0, showAllStates ? 100 : 6)
      .map((facet, i) => {
        const key = facet;
        const value = stateFacets.find((v) => v.value === key)?.count as number;
        return (
          <div className={styles['facet-options']} key={`${key}:${i}`}>
            <div className={styles['facet-checkbox']}>
              <input
                onChange={() => handleOnClick(facetKey, key, 'OR')}
                id={`${value}-${key}`}
                type='checkbox'
                name={`${value}-${key}`}
                value={`${value}`}
                checked={isFacetFilterSelected(
                  searchOptions?.params?.filter_by,
                  facetKey,
                  key
                )}
              />
              <label htmlFor={`${value}-${key}`}>
                {key} {value && <span>{numberWithCommas(value)}</span>}
              </label>
            </div>
          </div>
        );
      });

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Ships From</h2>
        </div>
        <div
          className={`${styles['facet-state-options-desktop']} ${
            !showAllStates ? styles['facet-state-options-column-override'] : ''
          }`}
        >
          <div style={{ marginRight: '10%' }}>{stateOptions.slice(0, stateOptions.length / 2)}</div>
          <div>{stateOptions.slice(stateOptions.length / 2)}</div>
        </div>
        {showAllStates && <div className={styles['facet-state-options-mobile']}>{stateOptions}</div>}

        <span className={styles['facet-title-link-see-all-left']} onClick={() => setShowAllStates(!showAllStates)}>
          {showAllStates ? 'Collapse' : 'See all'}
        </span>
      </div>
    );
  }

  function renderTypeFacets() {
    const facetKey = 'type.display';
    const typeFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === facetKey
    )?.counts;
    if (!typeFacets?.length) return null;

    const typeOptions = typeFacets.map((category) => {
      const { count, value } = category;
      return (
        <div className={styles['facet-options']} key={value}>
          <div className={styles['facet-checkbox']}>
            <input
              onChange={() => handleOnClick(facetKey, value)}
              id={`${value}-${count}`}
              type='checkbox'
              name={`${value}-${count}`}
              value={`${value}-${count}`}
              checked={isFacetFilterSelected(
                searchOptions?.params?.filter_by,
                facetKey,
                value
              )}
            />
            <label htmlFor={`${value}-${count}`}>
              {value} <span>{numberWithCommas(count)}</span>
            </label>
          </div>
        </div>
      );
    });

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Types</h2>
        </div>
        <>{typeOptions}</>
      </div>
    );
  }

  function renderCategoryFacets() {
    const categoryFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === 'genre.display'
    )?.counts;
    if (!categoryFacets?.length) return null;

    const availableCategoryCount = categoryFacets.length;

    const categoryOptions = categoryFacets
      .slice(0, showAllCategories ? 100 : 10)
      .map((category) => {
        const { count, value } = category;
        return (
          <div className={styles['facet-options']} key={value}>
            <div className={styles['facet-checkbox']}>
              <input
                onChange={() => handleOnClick('genre.display', value)}
                id={`${value}-${count}`}
                type='checkbox'
                name={`${value}-${count}`}
                value={`${value}-${count}`}
                checked={isFacetFilterSelected(
                  searchOptions?.params?.filter_by,
                  'genre.display',
                  value
                )}
              />
              <label htmlFor={`${value}-${count}`}>
                {value} <span>{numberWithCommas(count)}</span>
              </label>
            </div>
          </div>
        );
      });

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Categories</h2>
          {availableCategoryCount > 10 && (
            <span
              className={styles['facet-title-link']}
              onClick={() => setShowAllCategories(!showAllCategories)}
            >
              {showAllCategories ? 'Collapse' : 'See All'}
            </span>
          )}
        </div>
        <>{categoryOptions}</>
      </div>
    );
  }

  function renderFormatFacets() {
    const facetKey = 'format.display';
    const formatFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === facetKey
    )?.counts;
    if (!formatFacets?.length) return null;

    const formatOptions = formatFacets.map((category) => {
      const { count, value } = category;
      return (
        <div className={styles['facet-options']} key={value}>
          <div className={styles['facet-checkbox']}>
            <input
              onChange={() => handleOnClick(facetKey, value)}
              id={`${value}-${count}`}
              type='checkbox'
              name={`${value}-${count}`}
              value={`${value}-${count}`}
              checked={isFacetFilterSelected(
                searchOptions?.params?.filter_by,
                facetKey,
                value
              )}
            />
            <label htmlFor={`${value}-${count}`}>
              {value} <span>{numberWithCommas(count)}</span>
            </label>
          </div>
        </div>
      );
    });

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Format</h2>
        </div>
        <>{formatOptions}</>
      </div>
    );
  }

  function renderNoResults() {
    if (!searchResults.data.found) {
      return (
        <div className={styles['no-results']}>
          <h2>No results available</h2>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <>
      <section className={styles.facets}>
        <>
          {!IS_TITLE_QUERY && renderFormatFacets()}
          {!IS_TITLE_QUERY && renderConditionFacets()}
          {!IS_TITLE_QUERY && renderPriceFacet()}
          {renderStateFacet()}
          {renderTypeFacets()}
          {renderCategoryFacets()}
          {renderDealsFacet()}
          {listingsVsTitlesFacet()}
          <HashtagFacets
            searchOptions={searchOptions}
            searchResults={searchResults.data}
            loading={searchResults.isFetching}
            location={props.location}
          />
          {renderNoResults()}

          <div className={styles['bottom-footer-container']}>
            <Flex direction='row' justify='space-between'>
              <div>
                <Button
                  text='Clear all filters'
                  style='secondary'
                  onPress={() => {
                    handleClearAllFilters(allDefaultFacets);
                  }}
                ></Button>
              </div>
              <div>
                <Button
                  text={
                    numberWithCommas(searchResults.data.found)
                      ? `Show ${numberWithCommas(searchResults.data?.found)} ${pluralize(
                          'result',
                          searchResults.data?.found
                        )}`
                      : 'No results available'
                  }
                  onPress={() => {
                    dispatch({ type: ActionType.FLUSH_MODAL_DATA });
                  }}
                ></Button>
              </div>
            </Flex>
          </div>
        </>
      </section>
    </>
  );
}
