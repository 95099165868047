import { useEffect, useState } from 'react';
import 'rheostat/initialize';
import Rheostat from 'rheostat';
import { useRouter } from 'next/router';
import { UseQueryResult } from '@tanstack/react-query';
import { clearFacetValue, formatMoney, mergeFilters } from 'sdk';

import { Book, Title, TypesenseSearchProps } from 'types';

import { Links } from '../lib/helpers/link-helper';

import styles from '../styles/components/price-facet-slider.module.scss';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import useAnalytics from '../lib/hooks/use-analytics';

interface PriceSliderProps {
  searchResults: UseQueryResult<SearchResponse<Book | Title>, unknown>;
  searchOptions: TypesenseSearchProps;
  location?: string;
}

const MAX_PRICE = 10000;
const MIN_PRICE = 100;

export default function PriceSlider(props: PriceSliderProps) {
  const router = useRouter();
  const { analyticEvents } = useAnalytics();

  const amountFacet = props.searchResults.data?.facet_counts?.find((facet) => (facet.field_name as string) === 'amount');

  const [minValue, setMinValue] = useState(amountFacet?.stats?.min >= 0 ? amountFacet?.stats?.min : MIN_PRICE);
  const [maxValue, setMaxValue] = useState(Math.min(amountFacet?.stats?.max || MAX_PRICE, MAX_PRICE));
  const [maxRangeValue] = useState(Math.min(amountFacet?.stats?.max, MAX_PRICE));
  const [isDraggingSlider, setIsDraggingSlider] = useState(false);

  function setCurrentValues({ min, max }) {
    setMinValue(min);
    setMaxValue(max);
  }

  useEffect(() => {
    if (!props?.searchOptions?.params?.filter_by.includes('amount')) {
      setCurrentValues({
        min: amountFacet?.stats?.min >= 0 ? amountFacet?.stats?.min : MIN_PRICE,
        max: Math.min(amountFacet?.stats?.max || MAX_PRICE, MAX_PRICE),
      });
    }
  }, [!props?.searchOptions?.params?.filter_by.includes('amount')]);

  function updateAmountFilter() {
    if (minValue === MIN_PRICE && maxValue === MAX_PRICE) return clearFacetValue(props?.searchOptions?.params?.filter_by, 'amount');
    analyticEvents?.generalTrack('Search Action', {
      type: 'filter-action',
      subType: 'price',
      subTypeValue: { minValue, maxValue },
      location: props.location || 'unknown'
    });
    if (maxValue === MAX_PRICE) return mergeFilters(props?.searchOptions?.params?.filter_by, `amount:>=${minValue}`);
    return mergeFilters(props?.searchOptions?.params?.filter_by, `amount:[${minValue}..${maxValue}]`);
  }

  function handleOnSlideEnd() {
    setIsDraggingSlider(false);

    return router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...props.searchOptions,
          params: {
            ...props.searchOptions.params,
            filter_by: updateAmountFilter(),
            page: 0
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  function renderHandleControl(e: any) {
    const currentValue = Math.round(e['aria-valuenow']);
    const min = e['aria-valuemin'];

    let displayValue = formatMoney(currentValue, true, true);

    if (currentValue === MAX_PRICE) {
      displayValue = `Above ${formatMoney(MAX_PRICE, true, true)}`;
    }

    if (isDraggingSlider) {
      setCurrentValues({ min, max: currentValue });
    }

    return (
      <div className={styles.handle} {...e}>
        {isDraggingSlider && currentValue > 0 && <span style={{ left: e.style.left }}>{displayValue}</span>}
        <button className={styles.button} style={e.style} />
      </div>
    );
  }

  return (
    <section className={styles.slider}>
      <h2>
        {formatMoney(minValue, true, true)} - {formatMoney(maxValue, true, true)}
        {maxValue === MAX_PRICE ? '+' : ''}
      </h2>
      <Rheostat
        min={0}
        max={maxRangeValue}
        values={[minValue, maxValue]}
        handle={renderHandleControl}
        onSliderDragStart={() => setIsDraggingSlider(true)}
        onSliderDragEnd={() => handleOnSlideEnd()}
      />
    </section>
  );
}
